import React from "react";
import PromoList from "@/components/PromoList";

const PromoList2 = () => {
  return (
    <div>
      <PromoList cardNumer={2}/>
    </div>
  );
};

export default PromoList2;
